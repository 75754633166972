<template>
  <DataTable
    :value="data"
    tableStyle="min-width: 50rem"
    class="mt-10 clients-table max-h-[60vh]"
    scrollable
    scrollHeight="60vh"
  >
    <Column field="lastProcessingDate" header="Último processamento em">
      <template #body="slotProps">
        <div>
          <span class="table-values font-regular text-14323F date-pin-2">
            {{
              new Date(slotProps.data.lastProcessingDate).toLocaleString(
                "pt-BR"
              )
            }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="date" header="Data Base">
      <template #body="slotProps">
        <div
          class="is-clickable"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
            })
          "
        >
          <span class="table-values date-pin">
            {{ formatDate(slotProps.data.date) }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="totalOfFunds" header="Fundos Processados">
      <template #body="slotProps">
        <div
          class="is-clickable text-text font-bold"
          style="letter-spacing: -1px"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
            })
          "
        >
          {{
            slotProps.data.totalOfFunds -
            slotProps.data.statusWaitingOrProcessing
          }}
          /
          {{ slotProps.data.totalOfFunds }}
        </div>
      </template>
    </Column>
    <Column
      field="compliantFunds"
      :header="'Fundos Enquadrados'"
      class="xl:text-wrap text-nowrap compliant-color break-header-text"
    >
      <template #body="slotProps">
        <div
          class="is-clickable"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
              isCompliant: 'true',
            })
          "
        >
          {{ slotProps.data.compliantFunds }}
        </div>
      </template>
    </Column>
    <Column
      field="compliantFundsWithinTolerance"
      :header="'Fundos nos Período de Carência'"
      class="xl:text-wrap text-nowrap compliant-color break-header-text"
    >
      <template #body="slotProps">
        <div
          class="is-clickable text-[#D9BD04]"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
              isCompliant: 'true',
              isCompliantWithoutTolerance: 'false',
            })
          "
        >
          {{ slotProps.data.compliantFundsWithinTolerance }}
        </div>
      </template>
    </Column>
    <Column
      field="nonCompliantFunds"
      header="Fundos Desenquadrados"
      class="xl:text-wrap text-nowrap noncompliant-color break-header-text"
    >
      <template #body="slotProps">
        <div
          class="is-clickable"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
              isCompliant: 'false',
            })
          "
        >
          {{ slotProps.data.nonCompliantFunds }}
        </div>
      </template>
    </Column>
    <Column
      field="statusFailed"
      header="Falha no Processamento"
      class="xl:text-wrap text-nowrap failed-color break-header-text"
    >
      <template #body="slotProps">
        <div
          class="is-clickable"
          @click="
            handleClick(`/enquadramentos/filtered`, {
              date: formatDate(slotProps.data.date, 'yyyy-mm-dd'),
              status: 'failed',
            })
          "
        >
          {{ slotProps.data.statusFailed }}
        </div>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <img
          src="@/assets/svgs/up-right-from-square.svg"
          class="cursor-pointer min-w-[1rem] max-w-[1rem]"
          @click="handleFrameworkdList(slotProps.data.date)"
          v-tooltip="'Visualizar processamento'"
          alt="Visualizar processamento"
        />
      </template>
    </Column>
  </DataTable>

  <Paginator
    v-if="paginatorData"
    class="mt-5"
    :totalRecords="paginatorData.totalItems"
    :rows="paginatorData.itemsPerPage"
    @page="$emit('pageEvent', $event)"
  ></Paginator>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import { FrameworkGrouped } from "@/core/interfaces/Framework";
import { MetaValues } from "@/core/interfaces/Customers";
import { PropType } from "vue";
import { formatDate } from "../../common/formatDate";
import { useRouter, LocationQueryRaw } from "vue-router";

const router = useRouter();

defineEmits(["pageEvent"]);

defineProps({
  data: {
    type: Array as PropType<FrameworkGrouped[]>,
    required: true,
  },
  paginatorData: {
    type: Object as PropType<MetaValues>,
    required: false,
  },
});
const handleFrameworkdList = (date: string) => {
  router.push({
    name: "enquadramentos-lista",
    query: { date: date.split("T")[0] },
  });
};
const handleClick = (
  url: string,
  queryParams: Record<string, unknown> = {}
) => {
  if (url) {
    router.push({ path: url, query: queryParams as LocationQueryRaw });
  }
};
</script>

//manter sem scoped
<style lang="scss">
.break-header-text {
  max-width: 130px;
  text-align: center;
  line-height: 9%;

  @media (max-width: 1300px) {
    width: 100%;
    max-width: 100%;
  }
}

.p-datatable-table thead span {
  text-wrap: wrap !important;
}

.p-datatable-table tbody tr {
  height: 30px !important;

  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
    // // NOTE: controlar estilização de elementos das tabelas via main.scss, salvo últimos casos
    // font-size: 16px;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

// NOTE: controlar estilização de elementos das tabelas via main.scss, salvo últimos casos
// .p-column-title {
//   font-size: 16px;
//   font-weight: 500 !important;
//   text-wrap: wrap;
// }

// .p-datatable-table thead th {
//   font-size: 222px !important;
//   background-color: theme("colors.primary.DEFAULT");
//   color: theme("colors.white.DEFAULT");
//   padding-top: 8px !important;
//   padding-bottom: 6px !important;

//   &:last-child {
//     border-top-right-radius: 8px;
//     border-bottom-right-radius: 8px;
//   }

//   &:first-child {
//     border-top-left-radius: 8px;
//     border-bottom-left-radius: 8px;
//   }
// }

.date-pin,
.date-pin-2 {
  color: theme("colors.white.DEFAULT");
  background-color: theme("colors.secondary.2");
  border-radius: 5px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
}

.date-pin-2 {
  background-color: #8296ad;
}

.compliant-color {
  color: theme("colors.compliant.DEFAULT");
  font-weight: bold;
}

.noncompliant-color {
  color: theme("colors.noncompliant.DEFAULT");
  font-weight: bold;
}

.failed-color {
  color: theme("colors.failed.DEFAULT");
  font-weight: bold;
}

.is-clickable {
  cursor: pointer !important;
}

.table-values {
  @media (max-width: 1300px) {
    font-size: 12px !important;
    white-space: nowrap;
  }
}
</style>
