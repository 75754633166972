<template>
  <h2 class="text-text font-semibold text-[24px]">Enquadramentos</h2>
  <Alert v-show="reprocessedFundsCount && reprocessedFundsCount > 0" variant="warning" :total="reprocessedFundsCount"
    :updatedAtLastHours="updatedAtLastHours" @viewReprocessedFunds="viewReprocessedFunds" />

  <div class="mt-5 flex items-center gap-1 justify-end">
    <!-- eslint-disable-next-line no-irregular-whitespace -->
    <Button class="global-button secondary-button" label="Voltar" @click="router.go(-1)">
    </Button>
    <Button class="global-button primary-button" label="Filtrar"
      @click="(visible = true), (activeMode = 'filter'), getFunds()">
      <template #icon>
        <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3" />
      </template>
    </Button>
    <Button @click="(visible = true), getFunds(), (activeMode = 'process')" class="global-button tertiary-button"
      label="Processar">
      <template #icon>
        <img src="@/assets/svgs/refresh-white.svg" alt="filter icon" class="mr-3" />
      </template>
    </Button>
  </div>
  <TableLoading v-if="loading" class="mt-5" />
  <div v-show="!loading && frameworks.length > 0">
    <EnquadramentosTable :paginator-data="paginatorData" :data="frameworks" @pageEvent="getFundFrameworks" />
    <NoData v-if="!loading && frameworks.length == 0" />
  </div>
  <Dialog :draggable="false" v-model:visible="visible" modal :header="activeMode === 'process' ? 'Processar' : 'Filtrar' + ' enquadramento'
    " class="modal-dialog-frameworks">
    <hr class="mb-5" />
    <div class="flex flex-col mt-4" v-if="activeMode === 'process'">
      <label for="name">
        {{ activeMode === "process" ? "Processar" : "Filtrar" }} por fundo
      </label>
      <Dropdown @update:modelValue="getFunds($event)" :editable="true" :loading="loadingFunds" placeholder="Buscar..."
        v-model="selectedFund" optionLabel="name" :options="funds" class="dropdown-custom h-[38px] pl-[7px]" />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Data </label>
      <Calendar v-model="date" inline dateFormat="dd/mm/yy" :max-date="maxDate" />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button @click="
        (selectedFund = ''),
        (date = undefined),
        (selectedStatus = ''),
        getFundFrameworks()
        " label="Limpar" class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2" :disabled="loading" />
      <Button :loading="loadingProcessPost" :disabled="loading"
        :label="activeMode === 'process' ? 'Processar' : 'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary" @click="
          activeMode === 'process'
            ? processFund()
            : getFundFrameworks(undefined, true)
          " />
    </div>
    <div class="flex flex-col mt-[30px] pb-[30px]" v-if="activeMode != 'process'">
      <label for="name" class="mb-[15px]"> Fundo </label>
      <span class="text-gray-600 font-semibold mb-2">
        Buscar fundo por nome:
      </span>
      <Dropdown v-model="fundToFilter" @update:modelValue="getFunds($event)" :editable="true" :options="funds"
        optionLabel="name" placeholder="Selecionar fundo por nome..." class="dropdown-custom h-[38px] pl-[7px]"
        id="fund-to-filter-dropdown" @click="fundDocumentToFilter = ''" />
      <hr class="my-5" />
      <div class="flex xl:justify-end gap-2 mb-[20px]">
        <Button @click="fundToFilter = ''" label="Limpar"
          class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2" />
        <Button :label="'Filtrar'"
          class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary focus:bg-secondary active:bg-orange-500"
          @click="
            fundToFilter?.document
              ? router.push({
                name: 'enquadramentos-lista',
                query: { fundCnpj: fundToFilter.document },
              })
              : void 0
            " />
      </div>
    </div>
  </Dialog>
  <Dialog :draggable="false" v-model:visible="downloadDialogVisible" modal header="Exportar para Excel"
    class="modal-dialog-frameworks range-calendar">
    <label for="date" class="text-text font-semibold">
      Selecionar Data para Exportar
      <Calendar selectionMode="range" :manualInput="false" :max-date="maxDate" v-model="downloadDate" id="date" inline
        class="w-full mt-5 range-calendar" dateFormat="yy/mm/dd" />
    </label>
    <hr class="my-5" />
  </Dialog>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import EnquadramentosTable from '@/components/enquadramentos/TableGrouped.vue';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import TableLoading from '@/components/loading/TableLoading.vue';
import NoData from '@/components/NoData.vue';
import Alert from '@/components/Alert.vue';
import { onMounted, ref } from 'vue';
import { EnquadramentosService } from '@/services/enquadramentos/EnquadramentosService';
import { FrameworkGrouped } from '@/core/interfaces/Framework';
import { FundService } from '@/services/funds/FundService';
import { Fund } from '@/core/interfaces/Funds';
import { useToast } from 'primevue/usetoast';
import { debounce } from 'chart.js/helpers';
import { useRouter } from 'vue-router';
const router = useRouter();
const reprocessedFundsCount = ref<number | undefined>(undefined);
const loadingProcessPost = ref(false);
const toast = useToast();
const loadingFunds = ref(false);
const loading = ref(false);
const visible = ref(false);
const date = ref<Date>();
const selectedFund = ref();
const fundToFilter = ref();
const fundDocumentToFilter = ref();
const downloadDialogVisible = ref(false);
const maxDate = ref(new Date());
const funds = ref<Fund[]>();
const frameworks = ref<FrameworkGrouped[]>([]);
const paginatorData = ref();
const activeMode = ref('');
const downloadDate = ref<Date>();
const selectedStatus = ref();
const updatedAtLastHours = ref(0);
const cvmType = ref('');


onMounted(() => {
	configureQueryParams();
	getFundFrameworks();
	getFundReprocessedCount();
});

const configureQueryParams = () => {
	const query = router.currentRoute.value.query;
	if (query.cvmType) {
		cvmType.value = query.cvmType as string;
	}
};

const viewReprocessedFunds = async () => {
	router.push({ name: 'relatorio-reprocessamentos' });
};
const getFundReprocessedCount = async () => {
	loading.value = true;
	updatedAtLastHours.value = 24;
	const response = await EnquadramentosService.fundReprocessedCount(
		updatedAtLastHours.value
	);
	if (!(response instanceof Error) && response) {
		console.log('response', response);
		reprocessedFundsCount.value = response;
	}
	loading.value = false;
};
const getFundFrameworks = async (
	pageData?: { page?: number },
	filter?: boolean
) => {
	loading.value = true;
	let filters: {
    page?: string;
    date?: string;
    cvmType?: string;
  } = {};
	if (pageData) {
		filters = {
			page: pageData.page ? (pageData.page + 1).toString() : '1',
		};
	}
	if (filter) {
		if (date.value) {
			const ano = date.value.getFullYear();
			const mes = String(date.value.getMonth() + 1).padStart(2, '0');
			const dia = String(date.value.getDate()).padStart(2, '0');
			const dataFormatada = `${ano}-${mes}-${dia}`;
			filters.date = dataFormatada;
		}
	}
	if (cvmType.value) {
		filters.cvmType = cvmType.value;
	}
	const response =
    await EnquadramentosService.getFundGroupedFrameworks(filters);
	if (!(response instanceof Error) && response.data) {
		frameworks.value = response.data;
		paginatorData.value = response.meta;
	}
	loading.value = false;
};
const getFunds = debounce(async (searchValue?: string) => {
	loadingFunds.value = true;
	const response = await FundService.getFunds(undefined, searchValue || '');
	if (!(response instanceof Error) && response.data) {
		funds.value = response.data;
	}
	loadingFunds.value = false;
}, 700);
const processFund = async () => {
	loadingProcessPost.value = true;
	if (date.value) {
		const dateObject = new Date(date.value);
		const dateOnly = dateObject.toISOString().split('T')[0];
		const payload = {
			fundId: selectedFund?.value?.id as number,
			date: dateOnly,
		};
		try {
			await EnquadramentosService.processFundFramework(payload);
			toast.add({
				severity: 'success',
				summary: 'Processamento criado com sucesso!',
				detail: '',
				life: 5000,
			});
			setTimeout(() => {
				visible.value = false;
				getFundFrameworks();
			}, 1000);
		} catch (error) {
			console.log(error);
			toast.add({
				severity: 'error',
				summary: 'Erro ao processar enquadramento',
				detail: error,
				life: 5000,
			});
		}
	}
	loadingProcessPost.value = false;
};

</script>
<style lang="scss" scoped>
input {
  border: solid 1px theme("colors.text.DEFAULT");
  padding: 0.2rem 1rem;
}

label {
  margin-bottom: 0.3rem;
  color: theme("colors.text.DEFAULT");
  font-weight: bold;
}

.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;

  .p-dropdown-trigger {
    background-color: red !important;
  }
}
</style>
<style>
.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;

  .p-dropdown-trigger {
    background-color: theme("colors.secondary.DEFAULT");
    color: theme("colors.white.DEFAULT");
  }
}

label.mb-\[15px\] {
  margin-bottom: 15px !important;
}
</style>
