import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import { FundPositionByAssetType } from "@/core/interfaces/FundPositionByAssetType";
import { CarteiraData } from "@/core/interfaces/CarteiraData";

const requestService = new RequestService();

export class FundPositionService {
  public static async getByFundAndDate(
    fundId: number,
    date: string
  ): Promise<any | AxiosError> {
    const path = `/fund-positions/by-fund-and-date/${fundId}/date/${date}`;

    try {
      const response = await requestService.get(path);

      return response;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async downloadByFundAndDate(
    fundId: number,
    date: string
  ): Promise<any | AxiosError> {
    const path = `/fund-positions/by-fund-and-date/${fundId}/date/${date}/download`;

    try {
      const response = await requestService.get(path);

      return response;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getFundCarteira(
    date: string,
    fundId: number
  ): Promise<CarteiraData[] | AxiosError> {
    const path = `fund-positions/by-fund-and-date/${fundId}/date/${date}`;
    try {
      const response = await requestService.get(path);
      return response as CarteiraData[];
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getCprCarteira(
    date: string,
    fundId: number
  ): Promise<CarteiraData[] | AxiosError> {
    const path = `fund-cprs/by-fund-and-date/${fundId}/date/${date}`;
    try {
      const response = await requestService.get(path);
      return response as CarteiraData[];
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getOlderPositionsByAssetType(
    assetTypeId: number | string,
    limit = 5
  ) {
    const reqPath = `/fund-positions/find-by-assettype-asc/${assetTypeId}`;
    try {
      const config = {
        params: {
          limit: limit,
        },
      };
      const response = await requestService.get<FundPositionByAssetType[]>(
        reqPath,
        config
      );
      if (response && response.length) {
        const limitedData = response?.slice(0, 5);
        return limitedData;
      } else {
        return [];
      }
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
}
