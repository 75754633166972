<template>
  <DataTable :value="data" tableStyle="min-width: 50rem" class="mt-10 clients-table max-h-[60vh]" scrollable
    scrollHeight="60vh">
    <Column field="id" header="ID"></Column>
    <Column field="manager" header="Gestor">
      <template #body="slotProps">
        <span v-tooltip="{ value: slotProps.data.manager.name, autoHide: false }">{{
          getShortenName(slotProps.data.manager.name) }}</span>
      </template>
    </Column>
    <Column field="name" header="Denominação Social">
      <template #body="slotProps">
        <span v-tooltip.top="{ value: slotProps.data.name, autoHide: false }">
          {{ getShortenName(slotProps.data.name) }}
        </span>
      </template>
    </Column>
    <Column field="document" header="CNPJ">
      <template #body="slotProps">
        <p style="min-width: 160px" v-tooltip.top="slotProps.data.document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        )">
          {{
            slotProps.data.document.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          }}
        </p>
      </template>
    </Column>
    <Column field="type" header="Tipo"></Column>
    <Column field="classCvm" header="Classe CVM"></Column>
    <Column header="Ação">
      <template #body="slotProps">
        <img src="@/assets/svgs/edit-dark.svg" class="cursor-pointer" v-tooltip.top="'Editar'" @click="
          router.push({
            name: 'funds-create',
            params: { mode: 'editar', id: slotProps.data.id },
          })
          " alt="edit button" />
      </template>
    </Column>
  </DataTable>
  <Paginator class="mt-5" :totalRecords="paginatorData.totalItems" :rows="paginatorData.itemsPerPage"
    @page="$emit('pageEvent', $event)"></Paginator>
</template>
<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import { Fund } from '@/core/interfaces/Funds';
import { MetaValues } from '@/core/interfaces/Customers';
import { PropType } from 'vue';
import { vMaska } from 'maska';
import { useRouter } from 'vue-router';
import { getShortenName } from '@/common/getShortenName';

const router = useRouter();
defineEmits(['pageEvent', 'frameworkDetails']);
defineProps({
  data: {
    type: Object as PropType<Fund[]>,
    required: true,
  },
  paginatorData: {
    type: Object as PropType<MetaValues>,
    required: true,
  },
});
</script>
//manter sem scoped
<style>
.p-column-title {
  margin: auto;
}

.p-datatable-table tbody tr {
  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.p-datatable-table thead th {
  background-color: theme("colors.primary.DEFAULT");
  color: theme("colors.white.DEFAULT");
  padding-top: 1rem;

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
