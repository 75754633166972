<template>
  <div v-if="loading">
    <Skeleton></Skeleton>
  </div>
  <div v-else>
    <div
      class="enquadramentos-box-value"
      :class="{ 'justify-start-force': !centerTitle, 'no-bg': noBg }"
    >
      <div class="xl:flex xl:justify-between w-full xl:items-center">
        <h2
          :class="{ 'text-center block mx-auto': centerTitle }"
          class="font-bold text-secondary-7 text-[15px] superXl:text-[16px] text-nowrap"
        >
          {{ props.title }}
        </h2>
        <button
          v-if="showDownloadButton"
          @click="$emit('visualizarCarteira')"
          class="btn-carteira"
        >
          Visualizar Carteira
          <img
            src="../../assets/svgs/arrow-up-right.svg"
            alt=""
            v-tooltip.top="'Abrir'"
          />
        </button>
        <div
          v-if="type"
          class="bg-[#4A657A30] text-[#2C5076 font-bold px-3 py-2 rounded-lg uppercase"
        >
          {{ type }}
        </div>
      </div>
      <div
        class="enquadramentos-box-value__content"
        :class="isStatus ? getStatus() : ''"
      >
        <span v-if="props.addToolTip" v-tooltip.top="value">
          {{ handleValue(value) }}
        </span>
        <span v-else-if="!isMessage">
          {{ value }}
        </span>

        <div class="" v-if="isMessage && details">
          <Message
            v-if="details?.message"
            :message="details?.message"
            :status="details?.status"
            :isCompliant="details.isCompliant"
          />
        </div>
      </div>
    </div>
    <div>
      <slot name="inner-content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import Message from "./Message.vue";
import Skeleton from "primevue/skeleton";

const props = defineProps({
  type: {
    type: String,
    default: "",
  },
  isTable: {
    type: Boolean,
    default: false,
  },
  noBg: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  centerTitle: {
    type: Boolean,
    default: true,
  },
  isStatus: {
    type: Boolean,
    default: false,
  },
  isCompliant: {
    type: Boolean,
    default: false,
  },
  isCompliantWithoutTolerance: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  addToolTip: {
    type: Boolean,
    default: false,
  },
  isMessage: {
    type: Boolean,
    default: false,
  },
  details: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showDownloadButton: {
    type: Boolean,
    default: false,
  },
});

const getStatus = () => {
  if (props.status.toLowerCase() == "enquadrado") {
    if (props.isCompliantWithoutTolerance) {
      return "status-success";
    } else {
      return "status-tolerance";
    }
  } else if (
    !props.status ||
    props.status?.toLowerCase() == "falha" ||
    props.status?.toLowerCase() == "não enquadrado"
  ) {
    return "bg-red-500 text-white";
  } else {
    return "bg-text";
  }
};

const handleValue = (value: string) => {
  if (value.length > 12 && props.addToolTip) {
    return value.substring(0, 12) + "...";
  } else {
    return value;
  }
};
</script>

<style lang="scss" scoped>
.enquadramentos-box-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__content {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: center;
    display: inline-block;
    padding: 0.6rem 0.3rem;
    margin-top: 0.3rem;
    border-radius: 8px;
    width: 100%;
    @media (min-width: 1800px) {
      font-size: 14px;
    }
    @media (max-width: 767.98px) {
      padding: 0.5rem;
    }
  }
  &__content:not(.status-success):not(.status-tolerance):not(.bg-red-500):not(
      .bg-text
    ) {
    background-color: theme("colors.neutral.10");
  }
}

.status-success {
  background: theme("colors.details.bg-success");
  border: 1px solid theme("colors.details.text-success");
  border-radius: 8px;
  color: theme("colors.details.text-success");
}
.status-tolerance {
  background: theme("colors.details.bg-warning");
  border: 1px solid theme("colors.details.text-warning");
  border-radius: 8px;
  color: theme("colors.details.text-warning");
}

.justify-start-force {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.no-bg .enquadramentos-box-value__content {
  background-color: transparent !important;
  padding: 0 !important;
}

.btn-carteira {
  background-color: #f4f4f4;
  color: #0092c8;
  border: solid 1px #0092c8;
  border-radius: 4px;
  font-weight: 500;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  margin-bottom: 16px;
  img {
    width: 30px;
    position: relative;
    top: 2px;
    margin-left: 4px;
  }
}
</style>
