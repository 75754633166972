<template>
   
    <IconField class="table-search-box">
        <InputIcon class="search-icon-wrapper">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="search-icon">
                <path
                    d="M6.5 13.5C4.68333 13.5 3.14583 12.8708 1.8875 11.6125C0.629167 10.3542 0 8.81667 0 7C0 5.18333 0.629167 3.64583 1.8875 2.3875C3.14583 1.12917 4.68333 0.5 6.5 0.5C8.31667 0.5 9.85417 1.12917 11.1125 2.3875C12.3708 3.64583 13 5.18333 13 7C13 7.73333 12.8833 8.425 12.65 9.075C12.4167 9.725 12.1 10.3 11.7 10.8L17.3 16.4C17.4833 16.5833 17.575 16.8167 17.575 17.1C17.575 17.3833 17.4833 17.6167 17.3 17.8C17.1167 17.9833 16.8833 18.075 16.6 18.075C16.3167 18.075 16.0833 17.9833 15.9 17.8L10.3 12.2C9.8 12.6 9.225 12.9167 8.575 13.15C7.925 13.3833 7.23333 13.5 6.5 13.5ZM6.5 11.5C7.75 11.5 8.8125 11.0625 9.6875 10.1875C10.5625 9.3125 11 8.25 11 7C11 5.75 10.5625 4.6875 9.6875 3.8125C8.8125 2.9375 7.75 2.5 6.5 2.5C5.25 2.5 4.1875 2.9375 3.3125 3.8125C2.4375 4.6875 2 5.75 2 7C2 8.25 2.4375 9.3125 3.3125 10.1875C4.1875 11.0625 5.25 11.5 6.5 11.5Z"
                    fill="#333333" />
            </svg>
        </InputIcon>

        <InputText :placeholder="placeholder ?? 'Buscar'" v-model="searchQuery" @input="onInputChange" />

        <InputIcon v-if="showClearIcon" @click="clearSearch">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="clear-icon">
                <path
                    d="M9.70404 8.29221C10.0945 8.68265 10.0945 9.31673 9.70404 9.70717C9.3136 10.0976 8.67952 10.0976 8.28908 9.70717L5 6.41496L1.70779 9.70405C1.31735 10.0945 0.683273 10.0945 0.292831 9.70405C-0.0976105 9.3136 -0.0976105 8.67953 0.292831 8.28908L3.58504 5L0.295955 1.70779C-0.0944869 1.31735 -0.0944869 0.683273 0.295955 0.292831C0.686397 -0.0976105 1.32047 -0.0976105 1.71092 0.292831L5 3.58504L8.29221 0.295956C8.68265 -0.0944862 9.31673 -0.0944862 9.70717 0.295956C10.0976 0.686398 10.0976 1.32047 9.70717 1.71092L6.41496 5L9.70404 8.29221Z"
                    fill="#333333" />
            </svg>
        </InputIcon>
    </IconField>
   
</template>
<script setup lang="ts">
import { ref, watch, toRef } from 'vue';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';



const props = defineProps({
	placeholder: {
		type: String,
		required: false,
	},
	searchHandler: {
		type: Function,
		required: true,
	},
	setClearSearch: {
		type: Boolean,
		required: false,
	}
});

const searchQuery = ref('');
const showClearIcon = ref(false);
const setClearSearchRef = toRef(props, 'setClearSearch');


watch(setClearSearchRef, () => {

	
	clearSearch();
	
});

function onInputChange() {
	showClearIcon.value = searchQuery.value.length > 0;
	props.searchHandler(searchQuery.value);
}

function clearSearch() {
	searchQuery.value = '';
	showClearIcon.value = false;
	props.searchHandler('');
}
</script>

<style>
.table-search-box {
    position: relative;
}

.table-search-box input {
    height: 100%;
    width: 363px;
    padding-left: 40px;
    background-color: #F2F2F2;
    border: 1px solid #33333327;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

.search-icon-wrapper {
    position: static;
}

.table-search-box .search-icon {
    position: absolute;
    left: 13px;
    top: calc(50% - 9px)
}

.clear-icon {
    cursor: pointer;
}
</style>
