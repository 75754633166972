<template>
  <Toast />
  <div class="flex w-full" :class="setRouterViewWrapperBg()">
    <Sidebar v-if="!isLoginPage()" />
    <div class="" :class="isLoginPage() ? '' : 'px-1 xl:px-16 xl:py-16 mt-16  xl:mt-0 w-[100%] overflow-auto'
      ">
      <RouterView />
    </div>
  </div>
</template>

<script setup lang="ts">
import Sidebar from '@/components/sidebar/Sidebar.vue';
import { useRoute } from 'vue-router';
import Toast from 'primevue/toast';
const route = useRoute();


const isLoginPage = () => {
  return route.name === 'login' || route.name === 'password-recovery'
    ? true
    : false;
};

const setRouterViewWrapperBg = () => {

  switch (route.name) {
    case 'home':
      return 'bg-s6econdary-';
    default:
      return '';
  }
};
</script>

<style lang="scss">
label {
  color: theme("colors.text.DEFAULT");
  font-weight: 600;
  font-size: 20px;
}

// // NOTE: controlar estilização de elementos das tabelas via main.scss, salvo últimos casos
// .p-column-title {
//   font-size: 13px!important;
// }</style>
