<template>
  <DataTable
    :value="data"
    tableStyle="min-width: 50rem"
    class="mt-10 clients-table max-h-[60vh]"
    scrollable
    scrollHeight="60vh"
  >
    <Column field="date" header="Data Base">
      <template #body="slotProps">
        <div>
          <span class="table-values date-pin">
            {{ formatDate(slotProps.data.date.split("T")[0], "dd/MM/yyyy") }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="isCompliant" header="Enquadrado">
      <template #body="slotProps">
        <div class="flex justify-center">
          <img
            src="@/assets/svgs/warning-icon.svg"
            alt="enquadrado no período de carência"
            v-if="
              slotProps.data.isCompliant &&
              !slotProps.data.isCompliantWithoutTolerance
            "
          />
          <img
            src="@/assets/svgs/correctsign.svg"
            alt="enquadrado sign"
            v-else-if="slotProps.data.isCompliant"
          />
          <img
            src="@/assets/svgs/incorrect.svg"
            alt="não enquadrado sign"
            v-else-if="
              !slotProps.data.isCompliant && slotProps.data.isCompliant !== null
            "
          />

          <img src="@/assets/svgs/null.svg" alt="aguardando" v-else />
        </div>
      </template>
    </Column>
    <Column
      field=""
      header="Denominação Social"
      class="xl:text-wrap text-nowrap"
    >
      <template #body="slotProps">
        <span v-v-tooltip.top="{ value: slotProps.data.fund.name }">
          {{ getShortenName(slotProps.data.fund.name) }}
        </span>
      </template>
    </Column>
    <Column field="" header="CNPJ" class="">
      <template #body="slotProps">
        <p style="min-width: 160px">
          {{
            slotProps.data.fund.document.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          }}
        </p>
      </template>
    </Column>
    <Column field="" header="Tipo" class="xl:text-wrap text-nowrap">
      <template #body="slotProps">
        {{ slotProps.data.fund.type }}
      </template>
    </Column>
    <Column field="" header="Classe" class="xl:text-wrap text-nowrap">
      <template #body="slotProps">
        {{ slotProps.data.fund.classCvm }}
      </template>
    </Column>
    <!-- <Column field="date" header="Data">
      <template #body="slotProps">
        <div>
          <span class="table-values font-regular text-14323F dark:text-ffffff">
            {{ formatDate(slotProps.data.date) }}
          </span>
        </div>
      </template>
    </Column> -->
    <Column field="status" header="Status">
      <template #body="slotProps">
        <Status :status="slotProps.data.status" />
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <img
          src="@/assets/svgs/eye.svg"
          class="cursor-pointer min-w-[1rem] max-w-[1rem]"
          @click="handleFrameworkdList(slotProps.data)"
          alt="Detalhes do Enquadramento"
          v-tooltip.top="'Visualizar'"
        />
      </template>
    </Column>
  </DataTable>
  <Paginator
    class="mt-5"
    :totalRecords="paginatorData.totalItems"
    :rows="paginatorData.itemsPerPage"
    @page="$emit('pageEvent', $event)"
  ></Paginator>
</template>
<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import { FundFramework } from "@/core/interfaces/Framework";
import { MetaValues } from "@/core/interfaces/Customers";
import { PropType } from "vue";
import { getShortenName } from "@/common/getShortenName";
import Status from "./Status.vue";
import { formatDate } from "@/common/formatDate";
import { useRouter } from "vue-router";

defineEmits(["pageEvent", "frameworkDetails"]);
defineProps({
  data: {
    type: Object as PropType<FundFramework[]>,
    required: true,
  },
  paginatorData: {
    type: Object as PropType<MetaValues>,
    required: true,
  },
});

const router = useRouter();

const handleFrameworkdList = (value: { id: number }) => {
  console.log(value);
  router.push({
    name: "enquadramentos-detalhes",
    params: { id: value.id },
  });
};
</script>
//manter sem scoped
<style>
p .p-datatable-table tbody tr {
  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.p-datatable-table thead th {
  background-color: theme("colors.primary.DEFAULT");
  color: theme("colors.white.DEFAULT");
  padding-top: 1rem;

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
