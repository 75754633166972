<template>
  <div class="toggler-mobile xl:hidden">
    <img
      class="cursor-pointer"
      src="../../assets/svgs/x-1.svg"
      alt="menu toggler"
      v-if="isMenuExpanded"
      @click="handleExpandedMenu(false)"
    />
    <img
      class="cursor-pointer"
      src="../../assets/svgs/hamburguer-new.svg"
      alt="menu toggler"
      @click="handleExpandedMenu(true)"
      v-else
    />
  </div>
  <div class="sidebar" :class="{ 'menu-hidden': !isMenuExpanded }">
    <div class="sidebar__toggler">
      <transition>
        <img
          class="cursor-pointer"
          src="../../assets/svgs/x-1.svg"
          alt="menu toggler"
          v-if="isMenuExpanded"
          @click="handleExpandedMenu(false)"
        />
        <img
          class="cursor-pointer"
          src="../../assets/svgs/hamburguer-new.svg"
          alt="menu toggler"
          @click="handleExpandedMenu(true)"
          v-else
        />
      </transition>
    </div>
    <div class="sidebar__logo-wrapper">
      <Logo v-if="isMenuExpanded" />
    </div>
    <div class="sidebar__routes">
      <div class="" v-for="(item, index) in routes" :key="index">
        <div
          :class="handleActiveRoute(item.meta.displayName as string)"
          class="sidebar__menu-button"
          @click="toggleSubroutes(index, item)"
        >
          <div class="sidebar__button">
            <img :src="getMenuIcon(item.meta.icon as string)" alt="icon" />
            <span class="flex items-center gap-3">
              <span v-if="isMenuExpanded">{{ item.meta.displayName }}</span>
              <img
                v-if="item.meta.displayName !== 'Início' && isMenuExpanded"
                :class="{ 'rotate-180': !showSubroutes[index] }"
                src="../../assets/svgs/arrowdown-black.svg"
                alt="icon"
              />
            </span>
          </div>
        </div>
        <div v-if="item.name === 'enquadramentos' && showSubroutes[index]">
          <div class="sidebar__button font-bold ml-4 mt-2">
            <span class="flex items-center gap-3">
              <span v-if="isMenuExpanded" class="cursor-pointer">
                Lei 14.754
              </span>
              <img
                v-if="item.meta.displayName !== 'Início' && isMenuExpanded"
                :class="{ 'rotate-180': !showSubroutes[index] }"
                src="../../assets/svgs/arrowdown-black.svg"
                alt="icon"
              />
            </span>
          </div>
        </div>

        <transition name="slide-fade">
          <ol
            v-if="showSubroutes[index] && isMenuExpanded"
            class="sidebar__subroutes"
          >
            <router-link
              exact-active-class="menu-item-active"
              active-class="menu-item-active"
              class="flex items-center"
              @click="handleRouteClick"
              v-for="(subitem, index) in getRouteSubitem(item)"
              :key="index"
              :to="subitem.path"
            >
              {{ subitem.meta.displayName }}
            </router-link>
          </ol>
        </transition>
      </div>
    </div>
    <span class="sidebar__logout" @click="logout">Sair</span>
  </div>
</template>

<script setup lang="ts">
import Logo from "@/components/logo/Logo.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";

const router = useRouter();
import type {
  NavigationGuard,
  RouteRecordNormalized,
  RouteRecordRaw,
} from "vue-router";

const routes = ref<RouteRecordNormalized[]>([]);
const showSubroutes = ref<boolean[]>([]);
const isMenuExpanded = ref(true);

onMounted(() => {
  getRoutes();

  if (window.innerWidth < 1199) {
    isMenuExpanded.value = false;
  }
});

const handleActiveRoute = (item: string) => {
  const itemLower = item.toLowerCase();
  const fullRotasLower = router.currentRoute.value.fullPath.toLowerCase();

  const isHome = itemLower === "início" && fullRotasLower === "/";

  return fullRotasLower.includes(itemLower) || isHome ? "bg-active" : "";
};

const getRoutes = () => {
  routes.value = router
    .getRoutes()
    .filter((route) => route.meta.showInMenu)
    .sort((a, b) => (a.meta.order as number) - (b.meta.order as number));
  showSubroutes.value = new Array(routes.value.length).fill(false);
};

const getMenuIcon = (icon: string) => {
  if (!icon) return;
  const url = new URL(
    `../../assets/svgs/${icon.toLowerCase()}.svg`,
    import.meta.url
  ).href;
  console.log("icon url", url);
  return url;
};

const handleExpandedMenu = (value: boolean) => {
  isMenuExpanded.value = value;
};

const handleRouteClick = () => {
  window.innerWidth < 1199 ? (isMenuExpanded.value = false) : null;
};

const getRouteSubitem = (item: RouteRecordRaw) => {
  switch (item.name) {
    case "enquadramentos":
      return [
        {
          //TODO: Precisa passar depois do / do path o tipo de enquadramento, enquanto não passar, o router vai considerar ativo todas as rotas de enquadramento
          meta: {
            displayName: "FIDC",
          },
          path: "/enquadramentos/fidc",
          name: "fia",
        },
        {
          meta: {
            displayName: "FIA",
          },
          path: "/enquadramentos/fia",
        },
        {
          meta: {
            displayName: "ETF",
          },
          path: "/enquadramentos/etf",
        },
        {
          meta: {
            displayName: "Demais Fundos",
          },
          path: "/enquadramentos/demais-fundos",
        },
      ];
    default:
      break;
    case "Cadastros": {
      const cadastroSubRoutes = router
        .getRoutes()
        .filter(
          (route) => route.meta.isSubRoute && route.path.includes("cadastro")
        );

      return cadastroSubRoutes;
    }
  }
};

const toggleSubroutes = (index: number, item: RouteRecordRaw) => {
  isMenuExpanded.value = true;
  if (item.name === "enquadramentos") {
    router.push({ name: "enquadramentos" });
  }
  if (item.name === "home") {
    router.push("/");
  }
  showSubroutes.value[index] = !showSubroutes.value[index];
};

const logout = () => {
  localStorage.clear();
  router.push({ path: "/login" });
};
</script>

<style lang="scss" scoped>
.toggler-mobile {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 999999999999999999999999999999999999999999999999;
}

.sidebar {
  background-color: theme("colors.sidebar.wrapper");
  width: 280px;
  height: 100vh;
  position: sticky !important;
  padding: 0 20px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1199.98px) {
    width: 30%;
    position: fixed !important;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    pointer-events: all !important;
    opacity: 1;
    transition: 0.3s ease-in all;
  }

  @media (max-width: 767.98px) {
    width: 60%;
  }

  @media (max-width: 320.98px) {
    width: 80%;
  }

  &__toggler {
    box-shadow: -3px 5px 4px 0px #00000033;
    height: 84px;
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media (max-width: 1199.98px) {
      display: none;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }

  &__routes {
    margin-top: 2rem;
    flex-grow: 1;
  }

  &__menu-button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    font-family: Albert Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    padding: 0.66rem 1rem;
    transition: 0.3s ease-in-out all;
    width: auto;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
      background-color: theme("colors.sidebar.active");
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__subroutes {
    padding-left: 2rem;
    cursor: pointer;
    font-size: 14px;

    a {
      padding: 0.44rem 2rem;
      margin: 0.2rem 0;
      border-radius: 10px;
      transition: ease-in-out 0.3s all;
      white-space: nowrap;

      &:hover {
        background-color: theme("colors.sidebar.active");
      }
    }

    &__parent {
      margin-left: -1rem !important;
    }
  }

  &__logout {
    cursor: pointer;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.menu-item-active {
  background-color: theme("colors.sidebar.button-hover") !important;
  color: theme("colors.white.DEFAULT") !important;
}

.bg-active {
  background-color: theme("colors.sidebar.button-hover");
  color: theme("colors.white.DEFAULT") !important;

  img {
    filter: invert(1) brightness(2);
  }
}

.bg-none {
  background-color: transparent !important;
  color: initial !important;

  img {
    background-color: transparent !important;
    filter: none !important;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.menu-hidden {
  width: 80px;
  padding: 0;

  @media (max-width: 1199.98px) {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: auto;
    pointer-events: none;
    opacity: 0;
    transform: translateX(-50%);
  }

  .sidebar__toggler {
    box-shadow: none !important;
    background-color: transparent;
    left: 0;
    right: 0;
    margin: auto;
  }

  .sidebar__routes {
    margin-top: 4rem;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 1199.98px) {
      justify-content: center;
    }
  }

  .sidebar__button {
    background-color: #e1e0e1;
    width: 46px;
    height: 39px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 !important;
    border-radius: 8px;
    background-color: #d4d4d4;
    transition: 0.3s ease-in-out all;

    &:hover {
      background-color: theme("colors.sidebar.active") !important;
    }

    img {
      width: 15px;
      position: relative;
      left: 0.4rem;
    }
  }

  .sidebar__menu-button {
    padding: 0;
    border-radius: 8px;
  }
}
</style>
