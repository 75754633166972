<template>
  <DataTable :value="data" tableStyle="min-width: 50rem " class="mt-10 max-h-[60vh] reprocesed-table" scrollable
    scrollHeight="60vh">
    <Column field="updatedAt" header="Processado em:">
      <template #body="slotProps">
        <div v-tooltip.top="new Date(slotProps.data.updatedAt).toLocaleString('pt-BR')">
          <span class="table-values font-regular text-14323F date-pin-2 whitespace-nowrap">
            {{ new Date(slotProps.data.updatedAt).toLocaleString("pt-BR") }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="date" header="Data Base">
      <template #body="slotProps">
        <div v-tooltip.top="new Date(slotProps.data.updatedAt).toLocaleString('pt-BR')">
          <span class="text-table-2 font-regular text-14323F date-pin-2 bg-table-1">
            {{ new Date(slotProps.data.updatedAt).toLocaleString("pt-BR") }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="isCompliant" header="Enquadrado">
      <template #body="slotProps">
        <div class="flex justify-center" v-tooltip.top="slotProps.data.isCompliant?'Enquadrado':!slotProps.data.isCompliant && slotProps.data.isCompliant !== null?'Não Enquadrado':'Aguardando'">
          <img src="@/assets/svgs/correctsign.svg" alt="enquadrado sign" v-if="slotProps.data.isCompliant" />
          <img src="@/assets/svgs/incorrect.svg" alt="não enquadrado sign" v-else-if="
            !slotProps.data.isCompliant && slotProps.data.isCompliant !== null
          " />
          <img src="@/assets/svgs/null.svg" alt="aguardando" v-else />
        </div>
      </template>
    </Column>
    <Column header="Denominação Social">
      <template #body="slotProps">
        <span class="font-medium xl:text-wrap text-nowrap" v-tooltip.top="slotProps?.data?.fund?.name">
          {{ getShortenName(slotProps.data?.fund?.name) }}
        </span>
      </template>
    </Column>
    <Column field="" header="CNPJ" class="">
      <template #body="slotProps">
        <p style="min-width: 160px" v-tooltip.top="slotProps.data.fund.document.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5'
            )">
          {{
            slotProps.data.fund.document.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          }}
        </p>
      </template>
    </Column>
    <Column header="Tipo" class="font-medium">
      <template #body="slotProps">
        <div v-tooltip.top="slotProps.data.fund.type">
          <span>
            {{ slotProps.data.fund.type }}
          </span>
        </div>
      </template>
    </Column>
    <Column field="status" header="Status">
      <template #body="slotProps">
        <Status :status="slotProps.data.status" />
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <img src="@/assets/svgs/eye.svg" class="cursor-pointer min-w-[1rem] max-w-[1rem]"
          @click="handleFrameworkdList(slotProps.data)" alt="Detalhes do Enquadramento" v-tooltip.top="'Visualizar'" />
      </template>
    </Column>
  </DataTable>

  <Paginator v-if="paginatorData" class="mt-5" :totalRecords="paginatorData.totalItems"
    :rows="paginatorData.itemsPerPage" @page="$emit('pageEvent', $event)"></Paginator>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import { FrameworkGrouped } from '@/core/interfaces/Framework';
import { MetaValues } from '@/core/interfaces/Customers';
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { vMaska } from 'maska';
import { getShortenName } from '@/common/getShortenName';
import Status from './Status.vue';

const router = useRouter();

defineEmits(['pageEvent']);

defineProps({
  data: {
    type: Array as PropType<FrameworkGrouped[]>,
    required: true,
  },
  paginatorData: {
    type: Object as PropType<MetaValues>,
    required: false,
  },
});
const handleFrameworkdList = (value: { fundId: number }) => {
  console.log(value);
  router.push({
    name: 'enquadramentos-detalhes',
    params: { id: value.fundId },
  });
};
// const handleClick = (url: string, queryParams: Record<string, unknown> = {}) => {
// 	if (url) {
// 		router.push({ path: url, query: queryParams as LocationQueryRaw });
// 	}
// };

const handleStatus = (status: string) => {
  switch (status) {
    case 'finished':
      return { class: 'status-finished', label: 'Finalizado' };
    case 'failed':
      return { class: 'status-failed', label: 'Falha' };
    case 'waiting':
      return { class: 'bg-neutral-5 text-secondary', label: 'Em espera' };
    case 'processing':
      return { class: 'bg-neutral-5 text-secondary', label: 'Processando' };

    default:
      break;
  }
};
</script>

//manter sem scoped
<style lang="scss">
.break-header-text {
  max-width: 130px;
  text-align: center;
  line-height: 9%;
}

.p-datatable-table tbody tr {
  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
    // // NOTE: controlar estilização de elementos das tabelas via main.scss, salvo últimos casos
    // font-size: 14px;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

// // NOTE: controlar estilização de elementos das tabelas via main.scss, salvo últimos casos
// .p-column-title {
//   font-size: 16px;
//   font-weight: 500 !important;
//   text-wrap: wrap;
// }

.p-datatable-table thead th {
  font-size: 222px !important;
  background-color: theme("colors.primary.DEFAULT");
  color: theme("colors.white.DEFAULT");
  padding-top: 8px !important;
  padding-bottom: 6px !important;

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.date-pin,
.date-pin-2 {
  color: theme("colors.white.DEFAULT");
  background-color: theme("colors.secondary.2");
  border-radius: 5px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
}

.date-pin-2 {
  background-color: #8296ad;
}

.compliant-color {
  color: theme("colors.compliant.DEFAULT");
  font-weight: bold;
}

.noncompliant-color {
  color: theme("colors.noncompliant.DEFAULT");
  font-weight: bold;
}

.failed-color {
  color: theme("colors.failed.DEFAULT");
  font-weight: bold;
}

.is-clickable {
  cursor: pointer !important;
}
</style>
