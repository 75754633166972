<template>
 <div class="flex flex-col justify-center items-center">
    <img src="../assets/svgs/no-results.svg" alt="">
    <p class="text-text-1">{{ message }}</p>
    <Button @click="emit('buttonClick')" label="Filtrar novamente" class="bg-primary mt-4 text-white hover:bg-secondary" v-if="showButton" />
 </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
import Button from './Button.vue';

defineProps({
	showButton: {
		type: Boolean,
		required: false,
		default: true,
	},
	message: {
		type: String,
		required: false,
		default: 'Nenhum resultado encontrado para os filtros aplicados.'
	}
});

const emit = defineEmits(['buttonClick']);

</script>