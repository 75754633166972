<template>
	<div class="inline-block rounded-md font-bold text-nowrap status-wrapper" :class="handleStatus()?.class">
		<span class="status" v-tooltip.top="handleStatus()?.label">{{ handleStatus()?.label }}</span>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	status: {
		type: String,
		required: true,
	},
});

const handleStatus = () => {
	switch (props.status) {
		case 'finished':
			return { class: 'status-finished', label: 'Finalizado' };
		case 'failed':
			return { class: 'status-failed', label: 'Falha' };
		case 'waiting':
			return { class: 'status-waiting', label: 'Em espera' };
		case 'processing':
			return { class: 'status-processing', label: 'Processando' };

		default:
			break;
	}
};
</script>
<style scoped lang="scss">
.status {
	font-size: 12px;
	font-weight: 600;
	line-height: 14px;
	padding: 0px !important;
}

.status-wrapper {
	border-radius: 4px;
	width: 83px;
	height: 20px;
	padding: 3px 2px !important;
}

.status-failed {
	background-color: rgba(214, 69, 80, 0.07);
	color: #D64550;
}

.status-waiting {
	background-color: rgba(253, 238, 213, 0.658);
	color: #e7a842;
}

.status-finished {
	background-color: rgba(62, 196, 138, 0.07);
	color: #3EC48A;
}

.status-processing {
	background-color: rgba(84, 69, 214, 0.06);
	color: #4578D6;
}

@media (min-width: 1441px) {
	.status-wrapper {
		width: 110px;
		height: 25px;
		padding: 3px !important;
	}

	.status {
		font-size: 17px;
		font-weight: 600;
		line-height: 19px;
		padding: 0px !important;
	}
}
</style>
