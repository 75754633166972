<template>
  <h2 class="text-text font-semibold text-[24px]">Tipos de Ativos</h2>
  <div class="mt-5 flex items-center justify-between items-center">
    <span class="h-full back-home-router" @click="router.push({ name: 'home' })">
      Home</span>
    <div class="flex gap-1">
      <SearchBox :search-handler="searchHandler" placeholder="Buscar por Tipo de Ativo..." />
      <Button class="bg-primary hover:bg-secondary px-5 py-3 text-white border-solid border-transparent border-2"
        label="Filtrar" @click="visible = true">
        <template #icon>
          <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3" />
        </template>
      </Button>
    </div>
  </div>
  <TableLoading v-if="loading" class="mt-5" />

  <TableLoading v-if="loading" class="mt-5" />

  <div v-show="!loading && assetTypes.length > 0">
    <AssetTypeTable :paginator-data="paginatorData" :data="assetTypes" v-show="!loading && assetTypes && paginatorData"
      @pageEvent="getAssetTypes" />
  </div>

  <NoResultsFound message="Nenhum resultado encontrado." :show-button="false" v-if="assetTypes.length <= 0" />

  <Dialog :draggable="false" v-model:visible="visible" modal header="Filtrar" class="modal-dialog-frameworks">
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="codigo"> Tipo </label>
      <InputText v-model="code" />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo"> Revisado? </label>
      <Dropdown :editable="true" placeholder="Revisado?" v-model="selectedWasReviewed" optionLabel="name"
        :options="wasReviewedList" class="dropdown-custom" />
    </div>

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button @click="
        (fundDocument = ''), (code = ''), (type = { value: '', label: '' })
        " label="Limpar" class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"></Button>
      <FilterButton label="Filtrar" class="bg-primary text-white hover:bg-secondary-2"
        @click="getAssetTypes(), (visible = false)" />
    </div>
  </Dialog>
</template>


<script setup lang="ts">
import { AssetType } from '@/core/interfaces/AssetTypes';
import { AssetTypesService } from '@/services/asset-types/AssetTypesService';
import { onMounted, ref } from 'vue';
import AssetTypeTable from '@/components/asset-types/Table.vue';
import { MetaValues } from '@/core/interfaces/Customers';
import Button from 'primevue/button';
import FilterButton from '@/components/Button.vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TableLoading from '@/components/loading/TableLoading.vue';
import { useRoute, useRouter } from 'vue-router';
import SearchBox from '../../components/asset-types/SearchBox.vue';
import NoResultsFound from '@/components/NoResultsFound.vue';

const assetTypes = ref<AssetType[]>([]);
const paginatorData = ref({} as MetaValues);
const loading = ref(false);

const visible = ref(false);
const code = ref('');
const fundDocument = ref('');



const type = ref<{ value: string; label: string }>();

const wasReviewedList = ref([
  { name: 'Sim', value: 'true' },
  { name: 'Não', value: 'false' },
]);
const selectedWasReviewed = ref();
const route = useRoute();
const router = useRouter();



onMounted(() => {
  if (route.query.reviewed) {
    selectedWasReviewed.value = wasReviewedList.value.find(
      (option) => option.value === route.query.reviewed
    );
  }
  getAssetTypes();
});

const getAssetTypes = async (pageData?: { page?: number }) => {
  loading.value = true;
  const filters = {
    code: code.value,
    reviewed: selectedWasReviewed.value?.value,
    page: typeof pageData?.page == 'number' ? pageData?.page + 1 : 1,
  };
  console.log('caiu gets', filters);
  const response = await AssetTypesService.getAssetTypes(filters);

  if (response && !(response instanceof Error)) {
    assetTypes.value = response.data;
    paginatorData.value = response.meta;
  }
  loading.value = false;
};

function searchHandler(s: string) {
  code.value = s;
  getAssetTypes();
}

</script>
<style>
.back-home-router {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0092C8;
}
</style>