<template>
    <div class="card-expand">
      <div class="flex items-center justify-between">
        <h2 style="line-height: 100%" class="text-[24px] font-albert text-black font-medium ml-[10px] mb-3" v-if="props.title">{{ props.title }}</h2>
        <Button
            class="bg-secondary-6 px-3 py-2 text-secondary-2 border-solid border-secondary-2 border-2 card-button hover:brightness-100 transition-all"
            label="Ver todos"
            v-if="showButton"
            @click="router.push({ name: 'Fundos' })"
        />  
      </div>


        <slot name="content"></slot>
    </div>
</template>


<script setup lang="ts">
import { useRouter } from 'vue-router';
import { defineProps } from 'vue';
import Button from 'primevue/button';

const router = useRouter();
const props = defineProps({
	title: {
		type: String,
		required: false,
		default: '',
	},
	showButton: {
		type: Boolean,
		required: false,
		default: false,
	},
});

</script>

<style lang="scss" scoped>
  .card-button {
    @media (max-width: 1400px) {
      font-size: 14px;
      
      line-height: 100%;
    }    
  }
 
  .card-expand {
    border: solid 1px theme('colors.neutral.2');
    border-radius: 8px;
    padding: 20px;
  }

</style>