<template>
    <div class="enquadramentos-card">
        <slot name="content"></slot>
    </div>
</template>

<script setup lang="ts">

</script>


<style lang="scss" scoped>
.enquadramentos-card {
border-radius: 8px;
border: solid 1px #E6E6E6;
margin-top: 1rem;
padding: 1rem 2rem;
display: inline-block;
}

</style>